import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

import "./results.less";

const Results = ({ hide }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicResults {
        nodes {
          data {
            body {
              ... on PrismicResultsDataBodyResultsSlice {
                id
                primary {
                  results_title {
                    text
                  }
                }
                items {
                  result_icon {
                    url
                  }
                  result_stat {
                    text
                  }
                  result_value {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const resultTitle =
    data.allPrismicResults.nodes[0].data.body[0].primary.results_title.text;
  const resultGroup = data.allPrismicResults.nodes[0].data.body[0].items;

  return (
    <section className={`home-results ${hide ? "hide" : ""}`}>
      <article className="home-results-wrapper">
        <Container>
          <h2>{resultTitle}</h2>
          <Row className="result-grid">
            {resultGroup.map((r, idx) => {
              return (
                <Col key={idx} xs={6} md={3} className="single-result">
                  <img src={r.result_icon.url} alt="" />
                  <h3>{r.result_value.text}</h3>
                  <p>{r.result_stat.text}</p>
                </Col>
              );
            })}
          </Row>
        </Container>
      </article>
    </section>
  );
};

export default Results;
