import * as React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import BackgroundImg from "../../../images/background-two.png";

import "./attorney.less";

export default ({ data }) => {
  const aaTitle = data.aa_title.text;
  const aaSubtitle = data.aa_sub_title.text;
  const aaDescOne = data.aa_desc_one.text;
  const aaDescTwo = data.aa_desc_two.text;
  const aaGroup = data.aa_group;
  const aaBtnText = data.aa_button_title.text;
  let count = 0;
  return (
    <section className="home-attorney">
      <article className="attorney-wrapper">
        <Container>
          <h2>{aaTitle}</h2>
          <p className="sub-title">{aaSubtitle}</p>
          <Row className="attorney-content-container">
            <Col xs={12} lg={6} className="attorneys-container">
              <Row>
                {aaGroup.map((a, idx) => {
                  count++;
                  return (
                    <Col
                      key={count}
                      xs={12}
                      className={`single-attorney ${
                        count === 1 ? "first-attorney" : "second-attorney"
                      }`}
                      key={idx}
                    >
                      <img src={a.aa_lawyer_img.url} alt="" />
                      <h4>{a.aa_lawyer_name.text}</h4>
                      <p>{a.aa_lawyer_position.text}</p>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col xs={12} lg={6} className="attorney-description-container">
              <p className="first-para">{aaDescOne}</p>
              <p className="second-para">{aaDescTwo}</p>
              <Link to="/about" className="attorney-btn yellow-hover">
                {aaBtnText}
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="background-img">
          <img src={BackgroundImg} alt="" />
        </div>
      </article>
    </section>
  );
};
