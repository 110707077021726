import * as React from "react";
import { Container } from "react-bootstrap";
import Modal from "../../Modal/Modal";

import "./hero.less";

export default ({ data }) => {
  const heroImg = data.home_hero_image.url;
  const heroTitle = data.hero_section_title.text;
  const heroDesc = data.hero_description.text;
  const consultText = data.consult_link.text;
  const heroUsps = data.hero_usps;

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className="home-hero">
      <article className="home-hero-container ">
        <img src={heroImg} alt="Home Banner" className={"image-with-opacity"} />
        <div className="hero-content-container">
          <Container className="hero-content">
            <h1>{heroTitle}</h1>
            <p>{heroDesc}</p>
            <button
              className="btn consult blue-hover"
              onClick={() => {
                setModalShow(true);
              }}
            >
              {consultText}
            </button>
            {modalShow && (
              <Modal show={modalShow} onHide={() => setModalShow(false)} />
            )}
            <div className="usps">
              {heroUsps.map(({ usp_icon, usp_description }, idx) => {
              const icon = usp_icon.url;
              const desc = usp_description.text;
              return (
                <div key={idx} className="single-usp">
                  <img src={icon} alt="" />
                  <p>{desc}</p>
                </div>
              );
            })}
            </div>
          </Container>
          {/* <Container className="usps">
            {heroUsps.map(({ usp_icon, usp_description }, idx) => {
              const icon = usp_icon.url;
              const desc = usp_description.text;
              return (
                <div key={idx} className="single-usp">
                  <img src={icon} alt="" />
                  <p>{desc}</p>
                </div>
              );
            })}
          </Container> */}
        </div>
      </article>
    </section>
  );
};
