import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Tab, Container, Row, Col, Nav } from "react-bootstrap";
import get from "lodash/get";


import "./tabs.less";

export default ({ long }) => {
  const practiceAreaData = useStaticQuery(graphql`
    {
      allPrismicPracticeArea(sort: { order: ASC, fields: data___order }) {
        nodes {
          uid
          data {
            desktop_thumbnail {
              url
            }
            title
            sub_title {
              text
            }
            sub_heading{
              text
            }
            practice_excerpt1 {
              richText
              text
            }
          }
        }
      }
      allPrismicPracticeAreas {
        nodes {
          data {
            read_more_button_label {
              text
            }
            call_number {
              text
            }
            call_button_icon {
              url
            }
            call_btn_label {
              text
            }
          }
        }
      }
    }
  `);

  const practiceAreas = get(
    practiceAreaData,
    "allPrismicPracticeArea.nodes",
    []
  );

    const buttonLabel = practiceAreaData.allPrismicPracticeAreas.nodes[0].data.read_more_button_label.text;
    const callButtonLabel = practiceAreaData.allPrismicPracticeAreas.nodes[0].data.call_btn_label.text;
    const callButtonNumber = practiceAreaData.allPrismicPracticeAreas.nodes[0].data.call_number.text;
    const callButtonIcon = practiceAreaData.allPrismicPracticeAreas.nodes[0].data.call_button_icon.url;

  return (
    <section className="tabs-practice-areas">
      <article className="practice-areas-wrapper">
        <Container>
          <div className="practices-container tab">
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row>
                <Col md={4}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      {practiceAreas.map((p, idx) => {
                        return (
                          <Nav.Link key={idx} eventKey={idx}>
                            <h4>{p.data.title}</h4>
                          </Nav.Link>
                        );
                      })}
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md={8}>
                  <Tab.Content className="tab-body">
                    {practiceAreas.map((p, idx) => {
                      return (
                        <Tab.Pane key={idx} eventKey={idx}>
                          <h3>{p.data.sub_heading.text}</h3>
                          <img src={p.data.desktop_thumbnail.url} alt="" />
                          <p>{p.data.practice_excerpt1.text}</p>
                          <div className="btn-container">
                            <Link
                              to={`practice-areas/${p.uid}`}
                              className="btn see-more white-hover"
                            >
                              {" "}
                              {buttonLabel}
                              {" "}
                            </Link>

                            <a
                              href={`tel:+${callButtonNumber}`}
                              className="btn call-btn white-hover"
                            >
                              <img src={callButtonIcon} alt="" />
                              <span>
                                {callButtonLabel}
                              </span>
                            </a>
                          </div>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </article>
    </section>
  );
};
