import React from "react";
import Accordian from "./Accordian/Accordian"
import Tabs from "./Tabs/Tabs"

const Practices = ({ long }) => {
  return (
    <>
      <Accordian long={long}/>
      <Tabs long={long}/>
    </>
  );
};

export default Practices;
