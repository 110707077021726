import React, { Component } from "react";
import { Container } from "react-bootstrap";

import "./certificates.less";

export default class MultipleItems extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props.data;
    const certificateTitle = data.certification_title.text;
    const certificates = data.certificates;

    return (
      <section className="home-certificates">
        <div className="cream-wrapper"></div>
        <article className="certificates-wrapper">
          <Container>
            <h2>{certificateTitle}</h2>
            <div
              className={`slider-container slide-count-5 d-flex align-items-center justify-content-center`}
            >
              {certificates.map((c, idx) => {
                return (
                  <div className="single-certificate" key={idx}>
                    <img src={c.certificate.url} alt="" />
                  </div>
                );
              })}
              {/*<Slider*/}
              {/*  swipeToSlide={true}*/}
              {/*  focusOnSelect={true}*/}
              {/*  autoplay={true}*/}
              {/*  slidesToShow={certificates.length < 4 ? certificates.length : 4}*/}
              {/*  autoplaySpeed={6000}*/}
              {/*  lazyLoad={true}*/}
              {/*  infinite*/}
              {/*  dots*/}
              {/*  cssEase={"ease-in-out"}*/}
              {/*  responsive={[*/}
              {/*    {*/}
              {/*      breakpoint: 1024,*/}
              {/*      settings: {*/}
              {/*        slidesToShow:*/}
              {/*          certificates.length < 3 ? certificates.length : 3,*/}
              {/*        slidesToScroll: 1,*/}
              {/*      },*/}
              {/*    },*/}
              {/*    {*/}
              {/*      breakpoint: 600,*/}
              {/*      settings: {*/}
              {/*        slidesToShow: certificates.length < 2 ? 1 : 2,*/}
              {/*        slidesToScroll: 1,*/}
              {/*      },*/}
              {/*    },*/}
              {/*    {*/}
              {/*      breakpoint: 480,*/}
              {/*      settings: {*/}
              {/*        slidesToShow: 1,*/}
              {/*        slidesToScroll: 1,*/}
              {/*      },*/}
              {/*    },*/}
              {/*  ]}*/}
              {/*>*/}
              {/*  {certificates.map((c, idx) => {*/}
              {/*    return (*/}
              {/*      <div className="single-certificate" key={idx}>*/}
              {/*        <img src={c.certificate.url} alt="" />*/}
              {/*      </div>*/}
              {/*    );*/}
              {/*  })}*/}
              {/*</Slider>*/}
            </div>
          </Container>
        </article>
        <div className="blue-wrapper"></div>
      </section>
    );
  }
}
