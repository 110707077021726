import * as React from "react";
import { Container } from "react-bootstrap";
import Practices from "../../Practices/Practices";
import BackgroundImg from '../../../images/background-one.png';

import "./practice.less";

export default ({ data }) => {
  const practiceTitle = data.practice_title.text;

  return (
    <section className="home-practice-areas">
      <article className="home-practice-areas-wrapper">
        <Container>
          <h2>{practiceTitle}</h2>
          <Practices long={false} />
        </Container>
        <div className="background-img">
          <img src={BackgroundImg} alt="" />
        </div>
      </article>
    </section>
  );
};
