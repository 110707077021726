import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Accordion, Container } from "react-bootstrap";
import get from "lodash/get";

import "./accordian.less";

export default () => {
  const practiceAreaData = useStaticQuery(graphql`
    {
      allPrismicPracticeArea(sort: { order: ASC, fields: data___order }) {
        nodes {
          uid
          data {
            desktop_thumbnail {
              url
            }
            title
            sub_title {
              text
            }
            sub_heading {
              text
            }
            practice_excerpt1 {
              richText
              text
            }
          }
        }
      }
      allPrismicPracticeAreas {
        nodes {
          data {
            read_more_button_label {
              text
            }
          }
        }
      }
    }
  `);

  const practiceAreas = get(
    practiceAreaData,
    "allPrismicPracticeArea.nodes",
    []
  );

  const buttonLabel =
    practiceAreaData.allPrismicPracticeAreas.nodes[0].data
      .read_more_button_label.text;

  return (
    <section className="accordian-practice-areas">
      <article className="practice-areas-wrapper">
        <Container>
          <div className="practices-container accordian">
            <Accordion defaultActiveKey="0">
              {practiceAreas.map((p, idx) => {
                return (
                  <Accordion.Item
                    eventKey={idx}
                    className="single-accordian"
                    key={idx}
                  >
                    <Accordion.Header className="accordian-header">
                      {p.data.title}
                    </Accordion.Header>
                    <Accordion.Body className="accordian-body">
                      <h4>{p.data.sub_heading.text}</h4>
                      <img src={p.data.desktop_thumbnail.url} alt="" />
                      <p>{p.data.practice_excerpt1.text}</p>
                      <div className="btn-wrapper">
                        <Link to={`practice-areas/${p.uid}`} className="btn blue-hover">
                          {" "}
                          {buttonLabel}{" "}
                        </Link>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </Container>
      </article>
    </section>
  );
};
