import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackgroundImg from "../../../images/background-two.png";

import "./about.less";

export default ({ data }) => {
  const aboutSummary = data.about_summary.text;
  const mobileImage = data.about_mobile_image.url;
  const aboutDescOne = data.about_description_one.text;
  const aboutDescTwo = data.about_descrition_two.text;
  const aboutImageOne = data.about_image_one.url;
  const aboutImageTwo = data.about_image_two.url;
  return (
    <section className="home-about">
      <article className="home-about-wrapper">
        <Container>
          <Row className="home-about-grid">
            <Col sm={12} lg={6} className="about-summary">
              <h2>{aboutSummary}</h2>
            </Col>
            <Col sm={12} lg={6} className="home-about-content">
              <Row>
                <Col sm={12}>
                  <div className="mobile-image">
                    <img src={mobileImage} alt="" />
                  </div>
                </Col>
                <Col sm={12}>
                  <p className="desc-one">{aboutDescOne}</p>
                </Col>
                <Col sm={12} className="desc-two-container">
                  <p className="desc-two">{aboutDescTwo}</p>
                </Col>
                <Col sm={12} className="about-image-grid-container">
                  <Row className="about-image-grid">
                    <Col lg={6}>
                      <img src={aboutImageOne} alt="" />
                    </Col>
                    <Col lg={6}>
                      <img src={aboutImageTwo} alt="" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="background-img">
          <img src={BackgroundImg} alt="" />
        </div>
      </article>
    </section>
  );
};
