import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Hero from "../components/HomePage/Hero/Hero";
import About from "../components/HomePage/About/About";
// import Testimonial from "../components/HomePage/Testimonials/Testimonial";
import Practice from "../components/HomePage/PracticeAreas/Practice";
import Attorney from "../components/HomePage/Attorneys/Attorney";
import Certificates from "../components/HomePage/Certificates/Certificates";
import Contact from "../components/Contact/Contact";
import Results from "../components/Results/Results";
import Testimonials from "../components/Testimonials/Data";

// markup
const IndexPage = (props) => {
  const { data } = props;
  const homeData = data.allPrismicHomePage.edges[0].node.data;
  return (
    <Layout>
      <Hero data={homeData} />
      <About data={homeData} />
      <Testimonials />
      <Results />
      <Practice data={homeData} />
      <Attorney data={homeData} />
      <Certificates data={homeData} />
      <Contact map={true} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allPrismicHomePage {
      edges {
        node {
          data {
            aa_button_title {
              text
            }
            aa_desc_one {
              text
            }
            aa_desc_two {
              text
            }
            aa_group {
              aa_lawyer_img {
                url
              }
              aa_lawyer_name {
                text
              }
              aa_lawyer_position {
                text
              }
            }
            aa_sub_title {
              text
            }
            aa_title {
              text
            }
            about_description_one {
              text
            }
            about_descrition_two {
              text
            }
            about_image_one {
              url
            }
            about_image_two {
              url
            }
            about_mobile_image {
              url
            }
            about_summary {
              text
            }
            body {
              ... on PrismicHomePageDataBodyResultsSlice {
                id
                items {
                  result_icon {
                    url
                  }
                  result_stat {
                    text
                  }
                  result_value {
                    text
                  }
                }
                primary {
                  results_title {
                    text
                  }
                }
              }
            }
            certificates {
              certificate {
                url
              }
            }
            certification_title {
              text
            }
            consult_link {
              text
            }
            hero_description {
              text
            }
            hero_section_title {
              text
            }
            hero_usps {
              usp_description {
                text
              }
              usp_icon {
                url
              }
            }
            home_hero_image {
              url
            }
            practice_title {
              text
            }
            testimonial_sub_title {
              text
            }
            testimonial_title {
              text
            }
            testimonials {
              testimony {
                text
              }
              testimony_image {
                url
              }
              testimony_name {
                text
              }
              testimony_position {
                text
              }
            }
          }
        }
      }
    }
  }
`;
